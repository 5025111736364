// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Theme$GlazedWebsite from "../Theme.js";

function str(prim) {
  return prim;
}

var tags = {
  h1: 0,
  h2: 1,
  p: 2
};

var h1 = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueLighter)),
        /* [] */0
      ]
    ]);

var h2 = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueLighter)),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.text
              ]),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  700
                ]),
            /* :: */[
              Css.fontSize(Css.rem(0.75)),
              /* :: */[
                Css.opacity(0.2),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var p = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueLighter)),
        /* [] */0
      ]
    ]);

var Styles = {
  h1: h1,
  h2: h2,
  p: p
};

function HTMLText$Tag(Props) {
  var tag = Props.tag;
  var html = Props.html;
  var className;
  switch (tag) {
    case /* H1 */0 :
        className = h1;
        break;
    case /* H2 */1 :
        className = h2;
        break;
    case /* P */2 :
        className = p;
        break;
    
  }
  return React.createElement("span", {
              className: className
            }, html);
}

var Tag = {
  Styles: Styles,
  make: HTMLText$Tag
};

var h1$1 = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var h2$1 = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkerish)),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.heading
              ]),
          /* :: */[
            Css.fontSize(Css.rem(2.25)),
            /* :: */[
              Css.lineHeight(Css.rem(2.7)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var p$1 = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueLighter)),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.heading
              ]),
          /* :: */[
            Css.fontSize(Css.rem(0.88)),
            /* :: */[
              Css.opacity(0.8),
              /* :: */[
                Css.lineHeight(Css.rem(1.5)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var TextStyles = {
  h1: h1$1,
  h2: h2$1,
  p: p$1
};

function HTMLText$TextContent(Props) {
  var children = Props.children;
  var inline = Props.inline;
  var className = inline !== undefined ? Curry._1(Css.style, /* :: */[
          Css.paddingLeft(Css.rem(1.56)),
          /* [] */0
        ]) : Curry._1(Css.style, /* :: */[
          Css.paddingLeft(Css.rem(1.56)),
          /* :: */[
            Css.display(/* inlineBlock */-147785676),
            /* [] */0
          ]
        ]);
  return React.createElement("span", {
              className: className
            }, children);
}

var TextContent = {
  make: HTMLText$TextContent
};

function HTMLText(Props) {
  var tag = Props.tag;
  var className = Props.className;
  var children = Props.children;
  switch (tag) {
    case /* H1 */0 :
        return React.createElement("h1", {
                    className: Curry._1(Css.merge, /* :: */[
                          h1$1,
                          /* :: */[
                            className,
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(HTMLText$Tag, {
                        tag: /* H1 */0,
                        html: "<h1>"
                      }), React.createElement(HTMLText$TextContent, {
                        children: children
                      }), React.createElement(HTMLText$Tag, {
                        tag: /* H1 */0,
                        html: "</h1>"
                      }));
    case /* H2 */1 :
        return React.createElement("h2", {
                    className: Curry._1(Css.merge, /* :: */[
                          h2$1,
                          /* :: */[
                            className,
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(HTMLText$Tag, {
                        tag: /* H2 */1,
                        html: "<h2>"
                      }), React.createElement(HTMLText$TextContent, {
                        children: null
                      }, children, React.createElement(HTMLText$Tag, {
                            tag: /* H2 */1,
                            html: "</h2>"
                          })));
    case /* P */2 :
        return React.createElement("p", {
                    className: className
                  }, React.createElement(HTMLText$Tag, {
                        tag: /* P */2,
                        html: "<p> "
                      }), React.createElement(HTMLText$TextContent, {
                        children: children,
                        inline: /* () */0
                      }), React.createElement(HTMLText$Tag, {
                        tag: /* P */2,
                        html: " </p>"
                      }));
    
  }
}

var make = HTMLText;

var $$default = HTMLText;

export {
  str ,
  tags ,
  Tag ,
  TextStyles ,
  TextContent ,
  make ,
  $$default ,
  $$default as default,
  
}
/* h1 Not a pure module */
